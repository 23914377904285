.info {
  .contentsContainer {
    text-align: center;
    .text {
      margin-bottom: 30px;
      line-height: 1.8em;
    }
    .address {
      color: #5a381c;
      font-weight: bold;
      margin: 0;
    }
  }
}
