#concept {
  position: relative;
  .bg-text {
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.2em;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 600;
    font-family: $font-family-en;
    mix-blend-mode: overlay;
    @include media-breakpoint-up(sm) {
      font-size: calc(96px / 1440px * 100vw);
      margin-bottom: 75px;
    }
  }
  .brandContainer {
    .cont {
      .title {
        font-size: 29px;
        font-weight: 600;
        font-family: $font-family-en;
        margin-bottom: 30px;
        line-height: 1.5em;
        white-space: nowrap;
        letter-spacing: -0.02em;
        @include media-breakpoint-up(sm) {
          margin-bottom: 50px;
          line-height: 1.2em;
          letter-spacing: 0;
          font-size: calc(64px / 1440px * 100vw);
        }
      }
      .services-img {
        width: 100%;
        img {
          max-width: 100%;
        }
      }
      .text {
        margin: 0;
        font-size: 13px;
        line-height: 2em;
        @include media-breakpoint-up(sm) {
          margin-left: 50%;
          font-size: 15px;
        }
      }
    }
  }
}
