.midashi2 {
  position: absolute;
  left: 6vw;
  bottom: 100%;
  transform: rotate(90deg);
  transform-origin: bottom left;
  display: inline-block;
  @include media-breakpoint-up(sm) {
    left: 15vw;
  }
  .ja {
    color: #5a381c;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
    }
  }
  .en {
    font-size: 48px;
    font-weight: 600;
    font-family: $font-family-en;
    background: linear-gradient(90deg, #ffbc00 0%, rgba(#5a381c, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    line-height: 1em;
    @include media-breakpoint-up(sm) {
      font-size: 83px;
    }
  }
}
