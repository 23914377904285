.secondHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
    gap: 0;
    position: relative;
  }
  .img {
    width: 100%;
    height: 200px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 100%;
      flex-basis: 55%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    padding: 0 6vw;
    width: 100%;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
    }
    @include media-breakpoint-up(lg) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
      padding: 0 0 0 20vw;
      width: auto;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .title {
      .title-en {
        display: inline-block;
        font-size: 48px;
        font-weight: 600;
        font-family: $font-family-en;
        background: linear-gradient(90deg, #ffbc00 0%, rgba(#5a381c, 0.5) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 5px;
        white-space: nowrap;
        line-height: 1.1em;
        @include media-breakpoint-up(lg) {
          font-size: 83px;
        }
      }
      .title-ja {
        color: #5a381c;
        font-weight: bold;
        margin-bottom: 30px;
        font-size: 15px;
        @include media-breakpoint-up(md) {
          margin: 0;
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: 70px;
        }
      }
    }
    .text {
      margin: 0;
    }
  }
}
