#footer {
  position: relative;
  background-color: rgba($color: #d9d9d9, $alpha: 0.2);
  padding: 50px 0;
  @include media-breakpoint-up(sm) {
    padding: 75px 0 50px;
  }
  .pagetop {
    position: absolute;
    top: 10px;
    right: 6vw;
    @include media-breakpoint-up(sm) {
      top: 30px;
    }
    a {
      img {
      }
    }
  }
  .brandContainer {
    .footer-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 50px;
      margin-bottom: 50px;
      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
        gap: 5vw;
        margin-bottom: 70px;
      }
      .logo {
        width: 80px;
        @include media-breakpoint-up(sm) {
          min-width: 100px;
        }
        a {
          text-decoration: none;
          transition: $transition-base;

          img {
            width: 100%;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .gnav {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          gap: calc(50px / 1440px * 100vw);
          margin-top: 20px;
        }
        @include media-breakpoint-up(lg) {
          gap: 50px;
        }
        li {
          a {
            text-decoration: none;
            color: #5a381c;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            transition: $transition-base;
            font-family: $font-family-en;
            font-size: 13px;
            font-weight: 600;
            @include media-breakpoint-up(sm) {
              font-size: 15px;
            }
            &:before {
              background-color: $primary;
              content: "";
              width: 5px;
              height: 5px;
              border-radius: 50%;
              margin-right: 10px;
            }
            &:hover {
              color: $primary;
            }
          }
          &:last-child {
            margin: 0;
          }
          .gnav-child {
            list-style: none;
            padding: 12px 0 8px 10px;
            margin: 0;
            border-left: solid 1px #5a381c;
            @include media-breakpoint-up(sm) {
              padding: 55px 0 5px 10px;
            }
            li {
              margin-bottom: 20px;
              @include media-breakpoint-up(sm) {
                margin-bottom: 30px;
              }
              a {
                font-size: 10px;
                font-weight: 400;
                @include media-breakpoint-up(sm) {
                  font-weight: 500;
                }
                &:before {
                  display: none;
                }
              }
              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
    .copyright {
      text-align: center;
      margin: 0;
      font-size: 10px;
      font-weight: 400;
      @include media-breakpoint-up(sm) {
        font-size: 13px;
      }
    }
  }
}
