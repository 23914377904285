$headerHeight: 60px;
$headerHeightSM: 80px;
#header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: $headerHeight;
  z-index: $zindex-fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc($headerHeight + 15px) 0 6vw;
  @include media-breakpoint-up(sm) {
    height: $headerHeightSM;
    padding: 0 calc($headerHeightSM + 35px) 0 50px;
  }
  .logo {
    a {
      img {
      }
    }
  }
  .language {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    .ja {
      color: $body-color;
      text-decoration: none;
      font-size: 13px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
    .line {
      width: 1px;
      height: 15px;
      background-color: $body-color;
    }
    .en {
      color: $body-color;
      text-decoration: none;
      font-size: 13px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
    &.japanese {
      .en {
        color: #cacaca;
      }
    }
    &.english {
      .ja {
        color: #cacaca;
      }
    }
  }
}
