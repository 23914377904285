.midashi1 {
  margin-bottom: 50px;
  display: inline-block;
  .en {
    font-size: 32px;
    font-weight: 600;
    font-family: $font-family-en;
    background: linear-gradient(90deg, #ffbc00 0%, rgba(#5a381c, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 5px;
    line-height: 1em;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
  }
  .ja {
    color: #5a381c;
    font-weight: bold;
    margin: 0;
  }
  &.center {
    width: 80%;
    display: block;
    margin: 0 auto 50px;
    text-align: center;
    @include media-breakpoint-up(sm) {
      width: 50%;
    }
  }
}
