#services {
  .midashi1 {
    display: block;
    width: 50%;
    margin: 0 auto 50px;
    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }
  .services-img {
    width: 100%;
    margin: 0 auto 50px;
    @include media-breakpoint-up(sm) {
      max-width: 600px;
      margin: 0 auto 100px;
    }
    img {
      max-width: 100%;
    }
  }
  .digest {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 50px;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 50px;
      margin-bottom: 150px;
    }
    @include media-breakpoint-up(lg) {
      gap: 100px;
    }
    .img {
      position: relative;
      width: 90%;
      margin: 0 auto;
      @include media-breakpoint-up(sm) {
        width: auto;
        flex-basis: 50%;
      }
      &::before {
        content: "";
        width: 100%;
        padding-top: 110%;
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .dec {
        width: 100%;
        position: absolute;
        bottom: -50px;
        left: -1em;
        color: $white;
        transform: rotate(-90deg);
        transform-origin: top left;
        display: inline-block;
        margin: 0;
        font-size: 40px;
        @include media-breakpoint-up(sm) {
          bottom: -20px;
        }
      }
    }
    .cont {
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
      .number {
        color: #5a381c;
        font-family: $font-family-en;
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 5px;
        @include media-breakpoint-up(sm) {
          font-size: 15px;
          margin-bottom: 10px;
        }
      }
      .title {
        color: #5a381c;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 30px;
        @include media-breakpoint-up(sm) {
          font-size: 24px;
        }
      }
      .text {
        margin: 0;
        font-size: 13px;
        font-weight: 400;
        @include media-breakpoint-up(sm) {
          font-size: 15px;
        }
      }
      .morebtn {
      }
    }
    &.reverse {
      @include media-breakpoint-up(sm) {
        flex-direction: row-reverse;
      }
      .img {
        width: 100%;
        .dec {
          transform-origin: top left;
          left: 90%;
          bottom: -30px;
        }
      }
    }
  }
}
