.message-group {
  .catch {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
    color: #5a381c;
    line-height: 1.6em;
    @include media-breakpoint-up(sm) {
      font-size: 32px;
      margin-bottom: 50px;
    }
  }
  .message-digest {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 60px;
    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      gap: 50px;
    }
    @include media-breakpoint-up(lg) {
      gap: 80px;
      margin-bottom: 120px;
    }
    .img {
      position: relative;
      width: 100%;
      @include media-breakpoint-up(md) {
        width: auto;
        flex-basis: 30%;
      }
      &::before {
        content: "";
        width: 100%;
        padding-top: 100%;
        display: block;
        @include media-breakpoint-up(sm) {
          padding-top: 128%;
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cont {
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
      .header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        margin-bottom: 30px;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          margin-bottom: 50px;
          gap: 30px;
        }
        .position {
          margin: 0;
          font-size: 13px;
        }
        .name {
          margin: 0;
          font-size: 25px;
          @include media-breakpoint-up(sm) {
            font-size: 32px;
          }
        }
      }
      .midashi {
        font-weight: bold;
        margin-bottom: 15px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 30px;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          line-height: 1.8em;
          &:before {
            background-color: $body-color;
            content: "";
            min-width: 3px;
            height: 3px;
            border-radius: 50%;
            margin-right: 10px;
            margin-top: 0.8em;
          }
        }
      }
    }
  }
}
