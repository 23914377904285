@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  font-feature-settings: "pwid";
  letter-spacing: 0.05em;
  scroll-behavior: smooth;
}
.bg {
  background: url(../img/bg-sm.png);
  background-size: cover;
  @include media-breakpoint-up(sm) {
    background: url(../img/bg.png);
    background-size: cover;
  }
}
.system-body {
  background: url(../img/system-bg.png);
  background-size: cover;
}
.inhouse-body {
  background: url(../img/inhouse-bg.png);
  background-size: cover;
}
.app-body1 {
  background: url(../img/app-bg1.png);
  background-size: cover;
}
.app-body2 {
  background: url(../img/app-bg2.png);
  background-size: cover;
}
.app-body3 {
  background: url(../img/app-bg3.png);
  background-size: cover;
}
.global-body {
  background: url(../img/global-bg.png);
  background-size: cover;
}
.recruit-body {
  background: url(../img/recruit-bg.png);
  background-size: cover;
}
.company-body {
  background: url(../img/company-bg.png);
  background-size: cover;
}
.message-body {
  background: url(../img/message-bg.png);
  background-size: cover;
}
.news-body {
  background: url(../img/news-bg.png);
  background-size: cover;
}
.componentTitle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0.25;
  z-index: $zindex-fixed;
  padding: 15px;
  font-size: 10px;
  transition: $transition-base;
  &:hover {
    opacity: 1;
  }
}
@mixin fontSize($font_size: 10) {
  font-size: calc($font_size / 390 * 100vw);
}
@mixin fontSizeSM($font_size: 10) {
  font-size: calc($font_size / 820 * 100vw);
}
@mixin fontSizeLG($font_size: 10) {
  font-size: calc($font_size / 1366 * 100vw);
}
@import "components/header";
@import "components/toggleMenu";
@import "components/layout";
// @import "components/summary";
// @import "components/outline";
@import "components/digest";
// @import "components/digestCover";
// @import "components/article";
// @import "components/sectionHeader";
@import "components/secondHeader";
// @import "components/coverBgImg";
// @import "components/sideMenu";
// @import "components/tagCloud";
// @import "components/narrowThumbnailLink";
// @import "components/imgList";
// @import "components/carousels";
// @import "components/formTable";
@import "components/brandTable";
// @import "components/flow";
// @import "components/faq";
@import "components/newsList";
// @import "components/newsBar";
// @import "components/newsArchive";
// @import "components/catchCopyBar";
// @import "components/catchCopyBorder";
// @import "components/anchorLinks";
// @import "components/outlineCard";
@import "components/midashi1";
@import "components/midashi2";
// @import "components/midashi3";
@import "components/btn";
@import "components/breadcrumb";
@import "components/hero";
@import "components/concept";
@import "components/services";
@import "components/recruit";
@import "components/company";
@import "components/news";
@import "components/info";
@import "components/irregular";
@import "components/message";
@import "components/animation";
@import "components/footer";
@import "components/vif";
