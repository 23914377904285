.brandTable {
  display: table;
  width: 100%;
  font-size: 14px;
  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  .type {
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
    margin: 0;
    color: #5a381c;
  }
  dl {
    display: table-row;
    margin: 0;
    padding: 0;
    dt {
      display: table-cell;
      margin: 0;
      padding: 20px;
      border-bottom: 1px solid $primary;
      white-space: nowrap;
      width: 1%;
      @include media-breakpoint-up(sm) {
        padding: 30px 50px 30px 20px;
      }
    }
    dd {
      display: table-cell;
      margin: 0;
      padding: 20px 0;
      padding-left: 20px;
      border-bottom: 1px solid #d9d9d9;
      font-weight: 400;
      @include media-breakpoint-up(sm) {
        padding: 30px 0;
        padding-left: 20px;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          line-height: 1.8em;
          &:before {
            background-color: $body-color;
            content: "";
            width: 3px;
            height: 3px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
    }
    &:last-child {
      dt {
        border-bottom: 0;
      }
      dd {
        border-bottom: 0;
      }
    }
  }
  &.responsive {
    @include media-breakpoint-down(sm) {
      display: block;
      dl {
        display: block;
        dt {
          display: block;
          padding: 15px 0;
          width: 100%;
          border-bottom: 0;
        }
        dd {
          display: block;
          padding: 15px 0;
          border-top: 1px solid $primary;
          border-bottom: 0;
          width: 100%;
        }
        &:last-child {
          dt {
            border-bottom: 0;
          }
          dd {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
