.digest {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 40px;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 60px;
  }
  @include media-breakpoint-up(lg) {
    gap: 80px;
  }
  .video {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: auto;
      flex-basis: 50%;
    }
  }
  .img {
    position: relative;
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: auto;
      flex-basis: 50%;
    }
    &::before {
      content: "";
      width: 100%;
      padding-top: 100%;
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    @include media-breakpoint-up(sm) {
      flex: 1;
    }
    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: solid 1px #d9d9d9;
      @include media-breakpoint-up(sm) {
        padding-bottom: 20px;
      margin-bottom: 30px;
      }
      .number {
        color: $primary;
        font-family: $font-family-en;
        font-size: 20px;
        margin: 0;
      }
      .title {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        white-space: nowrap;
      }
    }
    .text {
      margin: 0;
      font-weight: 400;
      line-height: 1.8em;
    }
  }
  &.reverse {
    @include media-breakpoint-up(sm) {
      flex-direction: row-reverse;
    }
  }
}
