.irregular {
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 120px 0;
  }
  .midashi1 {
    .en {
      background: $white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .ja {
      color: $white;
    }
  }
  .cont {
    position: relative;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(sm) {
      margin-bottom: 100px;
    }
    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      margin-bottom: 20px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 40px;
      }
      .number {
        color: $primary;
        font-family: $font-family-en;
        font-size: 18px;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 20px;
        }
      }
      .title {
        color: $white;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 20px;
        }
      }
    }
    .list {
      padding-left: 46px;
      margin: 0;
      @include media-breakpoint-up(sm) {
        width: 60%;
      }
      li {
        color: $white;
        font-weight: 400;
        line-height: 2em;
      }
    }
    .img {
      position: absolute;
      bottom: -20px;
      right: 0;
      width: 70%;
      @include media-breakpoint-up(sm) {
        bottom: -50px;
        width: 70%;
      }
      &::before {
        content: "";
        width: 100%;
        padding-top: 52%;
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
