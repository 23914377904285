#recruit {
  .recruit-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    .midashi2 {
    }
    .cont {
      background: url(../img/recruit-sm@3x.png);
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      padding: 60px 0 60px 30px;
      margin-left: 25vw;
      @include media-breakpoint-up(sm) {
        background: url(../img/recruit@3x.png);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 100px 0 100px 100px;
        margin-left: 35vw;
      }
      @include media-breakpoint-up(lg) {
        margin-left: 27vw;
      }
      .text {
        p {
          color: $white;
          white-space: nowrap;
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 50px;
          line-height: 2em;
          @include media-breakpoint-up(sm) {
            font-size: 15px;
          }
        }
        .morebtn {
        }
      }
    }
  }
}
