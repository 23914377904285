#hero {
  position: relative;
  .mv {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: 216%;
      display: block;
      @include media-breakpoint-up(lg) {
        padding-top: 53%;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .scroll {
    position: absolute;
    width: 15px;
    height: 40px;
    left: 50%;
    bottom: 140px;
    transform: translateX(-50%);
    color: #5a381c;
    text-decoration: none;
    border: 1px solid #5a381c;
    border-radius: 50px;
    &::before {
      content: "scroll";
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      font-weight: bold;
    }
    span {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $primary;
      animation: 2s small-dot-move infinite running ease-out;
    }
  }
}

@keyframes small-dot-move {
  from {
    top: 15%;
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  to {
    top: 80%;
    opacity: 0.3;
  }
}
