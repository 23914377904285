#news {
  padding-bottom: 50px;
  @include media-breakpoint-up(sm) {
    padding-bottom: 110px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 150px;
  }
  .brandContainer {
    .news-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        gap: 150px;
      }
      .left {
        @include media-breakpoint-up(sm) {
          position: relative;
          flex-basis: 20%;
        }
        .midashi1 {
        }
        .morebtn {
          @include media-breakpoint-up(sm) {
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
      .right {
        width: 100%;
        @include media-breakpoint-up(sm) {
          flex: 1;
        }
        .newsList {
        }
      }
    }
  }
}
