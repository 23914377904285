.toggleMenu {
  position: fixed;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: $zindex-fixed;
  min-width: $headerHeight;
  min-height: $headerHeight;
  @include media-breakpoint-up(sm) {
    min-width: $headerHeightSM;
    min-height: $headerHeightSM;
  }
  .toggleMenuBtn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: $headerHeight;
    height: $headerHeight;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(sm) {
      width: $headerHeightSM;
      height: $headerHeightSM;
    }
    cursor: pointer;
    .bar,
    &::after {
      width: 30px;
      height: 2px;
      background-color: $body-color;
      content: "";
      transition: $transition-base;
      border-radius: 1px;
    }
    &:hover {
      &::after {
        transform: scaleX(100%);
      }
    }
    &.active {
      .bar {
        position: absolute;
        top: 50%;
        left: auto;
        transform: rotate(45deg);
        transform-origin: center;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: auto;
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
  .cont {
    padding: calc($headerHeight + 90px) 6vw 0;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    background: url(../img/toggle-bg-sm.png);
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(sm) {
      padding-top: $headerHeightSM;
      background: url(../img/toggle-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
    @include media-breakpoint-up(lg) {
      padding: calc($headerHeight + 145px) 18vw 0;
    }
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 6vw;
      height: $headerHeight;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include media-breakpoint-up(sm) {
        padding-left: 50px;
        height: $headerHeightSM;
      }
      a {
        transition: $transition-base;
        img {
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .gnav {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 30px;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
      }
      li {
        @include media-breakpoint-up(sm) {
          flex-basis: calc(100% / 5);
        }
        a {
          text-decoration: none;
          color: #5a381c;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          white-space: nowrap;
          transition: $transition-base;
          font-family: $font-family-en;
          font-weight: 600;
          @include media-breakpoint-up(sm) {
            font-size: 20px;
          }
          &:before {
            background-color: $primary;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
          }
          &:hover {
            color: $primary;
          }
        }
        &:last-child {
          margin: 0;
        }
        .gnav-child {
          list-style: none;
          padding: 12px 0 8px 10px;
          margin: 0;
          border-left: solid 1px #5a381c;
          @include media-breakpoint-up(sm) {
            padding: 48px 0 22px 10px;
          }
          li {
            margin-bottom: 20px;
            @include media-breakpoint-up(sm) {
              margin-bottom: 30px;
            }
            a {
              font-size: 13px;
              font-weight: 400;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 17px;
              @include media-breakpoint-up(sm) {
                font-size: 15px;
                font-weight: 500;
              }
              &:before {
                display: none;
              }
              .number {
                margin: 0;
                color: $primary;
              }
              .title {
                margin: 0;
                font-family: $font-family-base;
              }
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
