.breadcrumb {
  padding: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  .breadcrumb-item {
    color: $body-color;
    padding: 0;
    font-size: 10px;
    font-weight: 400;
    @include media-breakpoint-up(sm) {
      font-size: 13px;
    }
    a {
      text-decoration: none;
      color: $body-color;
    }
    svg {
      margin-left: 20px;
    }
    &::before {
      display: none;
    }
  }
}
