.newsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px solid #d9d9d9;
    padding: 15px 0;
    .date {
      color: $primary;
      font-family: $font-family-en;
      font-size: 13px;
      font-weight: bold;
    }
    .title-link {
      transition: $transition-base;
      color: #5a381c;
      margin: 0;
      svg {
        margin-left: 15px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .title,
    p {
      color: #5a381c;
      margin: 0;
    }
  }
}
