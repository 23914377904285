.morebtn {
  margin-top: 50px;
  a {
    text-decoration: none;
    color: $body-color;
    font-family: $font-family-en;
    font-weight: 600;
    font-size: 13px;
    white-space: nowrap;

    @include media-breakpoint-up(sm) {
      font-size: 15px;
    }
    svg {
      margin-right: 10px;
      width: 30px;
      transition: $transition-base;
      @include media-breakpoint-up(sm) {
        margin-right: 20px;
        width: 50px;
      }
    }
    &:hover {
      svg {
        transform: translateX(2px);
      }
    }
  }
}
