#company {
  .brandContainer {
    .company-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
      }
      .midashi1 {
      }
      .cont {
        width: 100%;
        @include media-breakpoint-up(sm) {
          width: auto;
        }
        .name {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .access {
          font-size: 13px;
          margin-bottom: 20px;
          line-height: 2em;
          @include media-breakpoint-up(sm) {
            font-size: 15px;
            line-height: 1.8em;
          }
        }
        dl {
          display: table-row;
          margin: 0;
          padding: 0;
          dt {
            display: table-cell;
            margin: 0;
            padding: 0 10px 10px 0;
            font-weight: 400;
            line-height: 1em;
            @include media-breakpoint-up(sm) {
              padding: 0 10px 20px 0;
            }
          }
          dd {
            display: table-cell;
            margin: 0;
            padding: 0 0 10px 10px;
            line-height: 1em;
            @include media-breakpoint-up(sm) {
              padding: 0 0 10px 20px;
            }
          }
        }
        .morebtn {
          margin-top: 50px;
          text-align: end;
          a {
            text-decoration: none;
            color: $body-color;
            font-family: $font-family-en;
            font-weight: 600;
            font-size: 13px;
            @include media-breakpoint-up(sm) {
              font-size: 15px;
            }
            svg {
              margin-right: 10px;
              width: 30px;
              @include media-breakpoint-up(sm) {
                margin-right: 20px;
                width: 50px;
              }
            }
          }
        }
      }
    }
  }
}
